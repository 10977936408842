import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import Loading from '../../../components/Loading/Loading';
import { Outlet, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './AccountSummary.module.scss';
import './../../../styles/_buttons.scss';
import { IGetPersonalDetailsResponse } from '../../../clients/AccountClient';
import useAccountClient from '../../../hooks/account/Client';
import useLoanClient from '../../../hooks/loan/Client';
import Typography from '@mui/material/Typography';
import Disbursing from './Disbursing/Disbursing';
import {
  IGetDebitOrderScheduleResponse,
  IGetRepayStatusResponse,
  IHasSettlementLetterResponse,
  IOpenLoanDetailsResponse,
} from '../../../clients/LoanClient';
import AccountTimeline from './AccountTimeline/AccountTimeline';
import ActiveLoan from './ActiveLoan/ActiveLoan';
import UploadDoc from './UploadDoc/UploadDoc';
import LoanAgreement from './LoanAgreement/LoanAgreement';
import DoNotEngage from './DoNotEngage/DoNotEngage';
import { RouterRoutes } from '../../../utils/RouterRoutes';
import Eft from './Repay/Eft/Eft';
import EasyPay from './Repay/EasyPay/EasyPay';
import InstantEft from './Repay/InstantEft/InstantEft';
import Arrears from './Arrears/Arrears';
import CounterOffer from './CounterOffer/CounterOffer';
import Timeout from './Timeout/Timeout';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import HowItWorks from './HowItWorks/HowItWorks';
import { Box, Container } from '@mui/material';
import { useTracking } from '../../../Tracking/TrackingContext';
import SliderWithFlap from '../../../components/Sliders/slider-with-flap/slider-with-flap';
import OptimonkContainer from '../../../components/OptimonkContainer/OptimonkContainer';

const Repay = lazy(() => import('./Repay/Repay'));
import { navigateToFailedOpenBanking, navigateToOpenBankingProcessing } from '../../../utils/Helpers/NavigationHelper';

const AccountSummary: React.FunctionComponent = () => {
  const [personalDetailsResponse, setPersonalDetailsResponse] = useState<IGetPersonalDetailsResponse>(
    // Initialize with an empty object instead of null
    {} as IGetPersonalDetailsResponse
  );
  const [isLoading, setIsLoading] = useState(true);
  const [openLoanDetailsResponse, setOpenLoanDetailsResponse] = useState<IOpenLoanDetailsResponse | null>(null);
  const [repayStatusResponse, setRepayStatusResponse] = useState<IGetRepayStatusResponse | null>(null);
  const [hasSettlementLetter, setHasSettlementLetter] = useState<IHasSettlementLetterResponse | null>(null);
  const [debitOrderScheduleResponse, setDebitOrderScheduleResponse] = useState<IGetDebitOrderScheduleResponse | null>(
    null
  );
  const accountClient = useAccountClient();
  const loanClient = useLoanClient();
  const [creditLimit, setCreditLimit] = useState(4000);
  const { displaySnackBar } = useSnackBar();
  const { SetExistingCustomer, SetIsPersonalDetailsComplete, GetAccountSummaryPageKey } = useTracking();
  const showAccountSummaryContent = openLoanDetailsResponse?.hasOpenLoan && !openLoanDetailsResponse?.isDne;
  const showOptimonkContainer: boolean =
    (!isLoading &&
      (openLoanDetailsResponse?.openLoanStatus === 3 ||
        openLoanDetailsResponse?.openLoanStatus === 5 ||
        openLoanDetailsResponse?.isDne)) ??
    false;
  const showOffersText: boolean =
    (!isLoading && (openLoanDetailsResponse?.openLoanStatus === 5 || openLoanDetailsResponse?.isDne)) ?? false;
  const navigate = useNavigate();
  const { isExistingCustomer } = useTracking();

  const [searchParams] = useSearchParams();
  const openBankingStatusCode = searchParams.get('status');

  //We are appending the page key so that marketing can track the url on GTM
  const appendPageKeyToUrl = () => {
    if (openLoanDetailsResponse && showAccountSummaryContent) {
      const pageKey = GetAccountSummaryPageKey(openLoanDetailsResponse.openLoanStatus);
      //using the window object to append the page key to the url because we don't want react to re-render the same component
      if (pageKey) window.history.replaceState(null, '', `/${RouterRoutes.myLoan}/${pageKey}`);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const personalDetailsPromise = accountClient.getPersonalDetails();
      const OpenLoanDetailsPromise = loanClient.getOpenLoanDetails();
      const [personalDetailsResponse, openLoanDetailsResponse] = await Promise.all([
        personalDetailsPromise,
        OpenLoanDetailsPromise,
      ]);

      if (openLoanDetailsResponse.openLoanStatus === 6 && openLoanDetailsResponse.loanData?.loanId) {
        const debitOrderScheduleResponse = await loanClient.getDebitOrderSchedule(
          openLoanDetailsResponse.loanData?.loanId
        );
        setDebitOrderScheduleResponse(debitOrderScheduleResponse);
      }

      SetExistingCustomer(openLoanDetailsResponse.hasClosedLoan ?? false);
      SetIsPersonalDetailsComplete(personalDetailsResponse.personalDetailsIsComplete ?? false);

      setPersonalDetailsResponse(personalDetailsResponse);
      setOpenLoanDetailsResponse(openLoanDetailsResponse);

      if (openLoanDetailsResponse.hasOpenLoan && openLoanDetailsResponse.loanData?.loanId) {
        const repayStatusResponsePromise = loanClient.getRepayStatus(openLoanDetailsResponse.loanData.loanId);
        const hasSettlementLetterPromise = loanClient.hasSettlementLetter(openLoanDetailsResponse.loanData.loanId);
        const [repayStatusResponse, hasSettlementLetterResponse] = await Promise.all([
          repayStatusResponsePromise,
          hasSettlementLetterPromise,
        ]);

        setRepayStatusResponse(repayStatusResponse);
        setHasSettlementLetter(hasSettlementLetterResponse);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
    // eslint-disable-next-line
  }, [accountClient, loanClient, displaySnackBar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    appendPageKeyToUrl();
  }, [openLoanDetailsResponse]);

  useEffect(() => {
    const loanId = openLoanDetailsResponse?.loanData?.loanId;
    if (openBankingStatusCode && loanId) {
      if (Number(openBankingStatusCode) <= 2000) {
        navigateToOpenBankingProcessing(navigate, loanId, isExistingCustomer);
      } else {
        const title = "We couldn’t successfully connect to your bank.";
        navigateToFailedOpenBanking(navigate, loanId, isExistingCustomer, openLoanDetailsResponse?.quotationData?.loanAmount ?? 0, title)
      }
    }
  }, [isExistingCustomer, navigate, openBankingStatusCode, openLoanDetailsResponse?.loanData?.loanId, openLoanDetailsResponse?.quotationData?.loanAmount]);

  // Redirect drop-offs to the open-banking processing screen if still processing
  useEffect(() => {
    const loanId = openLoanDetailsResponse?.loanData?.loanId;
    if (openLoanDetailsResponse?.loanStateResponse?.avaInfo?.pendingOpenBankingOutcome && loanId) {
      navigateToOpenBankingProcessing(navigate, loanId, isExistingCustomer);
    }
  }, [isExistingCustomer, navigate, openLoanDetailsResponse?.loanData?.loanId, openLoanDetailsResponse?.loanStateResponse?.avaInfo?.pendingOpenBankingOutcome]);

  let content = <></>;

  if (!personalDetailsResponse?.personalDetails?.customer)
    content = (
      <Container disableGutters sx={{ paddingX: '1.6rem' }}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
          fontWeight={400}
          lineHeight={'3.2rem'}
          letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
          paddingBottom={'2rem'}
        >
          Hello, welcome to Wonga!
        </Typography>
        <p>Let us show you around for you to get to know us better. </p>
      </Container>
    );

  if (
    (!openLoanDetailsResponse?.hasOpenLoan && openLoanDetailsResponse?.openLoanStatus !== 8) ||
    openLoanDetailsResponse.loanStateResponse?.applicationInfo?.isDeclined
  )
    content = (
      <>
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <Typography
            variant="h1"
            fontSize={{ md: '3.2rem', xs: '2.4rem' }}
            marginBottom={'1.2rem'}
            fontWeight={400}
            lineHeight={{ md: '3.5rem', xs: '2.5rem' }}
          >
            Hi {personalDetailsResponse?.personalDetails?.customer?.firstName}
          </Typography>
          <Typography>
            Your current trust rating means you can apply for up to <strong>R {creditLimit}</strong>{' '}
          </Typography>
        </Container>
        <br />
        <div className={styles['sliders-wrapper']}>
          <SliderWithFlap setCreditLimit={setCreditLimit} />
        </div>
        {/* <DebiCheck /> */}
        <HowItWorks />
      </>
    );

  if (showAccountSummaryContent) {
    if (openLoanDetailsResponse.openLoanStatus === 0) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <Timeout
            loanAmount={openLoanDetailsResponse.quotationData?.loanAmount ?? 0}
            term={openLoanDetailsResponse.quotationData?.term ?? 0}
            termInDays={openLoanDetailsResponse.quotationData?.termInDays ?? 0}
          />
        </Container>
      );
    }

    if (openLoanDetailsResponse.openLoanStatus === 1) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <LoanAgreement
            setOpenLoanDetailsResponse={setOpenLoanDetailsResponse}
            openLoanResponse={openLoanDetailsResponse}
          />
        </Container>
      );
    }

    if (openLoanDetailsResponse.openLoanStatus === 2) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <UploadDoc openLoanResponse={openLoanDetailsResponse} personalDetailsResponse={personalDetailsResponse} />
        </Container>
      );
    }
    if (openLoanDetailsResponse.openLoanStatus === 3 || openLoanDetailsResponse.openLoanStatus === 4) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <AccountTimeline
            loanStatus={openLoanDetailsResponse?.openLoanStatus}
            openLoanResponse={openLoanDetailsResponse}
          />
        </Container>
      );
    }
    if (openLoanDetailsResponse.openLoanStatus === 5) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <Disbursing openLoanResponse={openLoanDetailsResponse} />
        </Container>
      );
    }
    if (openLoanDetailsResponse.openLoanStatus === 6) {
      content = (
        <Container disableGutters>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index
                element={
                  <ActiveLoan
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetails={personalDetailsResponse}
                    repayStatus={repayStatusResponse}
                    debitOrders={debitOrderScheduleResponse?.debitOrderSchedules ?? []}
                  />
                }
              />
              <Route
                path={RouterRoutes.repay}
                element={
                  <Suspense
                    fallback={
                      <Box display={'flex'} justifyContent={'center'} height={'80vh'} width={'100%'}>
                        <Loading />
                      </Box>
                    }
                  >
                    <Repay
                      openLoanResponse={openLoanDetailsResponse}
                      hasSettlementLetterResponse={hasSettlementLetter}
                    />
                  </Suspense>
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.eft}`}
                element={
                  <Eft openLoanResponse={openLoanDetailsResponse} personalDetailsResponse={personalDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.instantEft}`}
                element={<InstantEft openLoanResponse={openLoanDetailsResponse} />}
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.easyPay}`}
                element={
                  <EasyPay
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetailsResponse={personalDetailsResponse}
                  />
                }
              />
            </Route>
          </Routes>
        </Container>
      );
    }
    if (openLoanDetailsResponse.openLoanStatus === 7) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index
                element={
                  <Arrears
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetails={personalDetailsResponse}
                    repayStatus={repayStatusResponse}
                  />
                }
              />
              <Route
                path={RouterRoutes.repay}
                element={
                  <Repay openLoanResponse={openLoanDetailsResponse} hasSettlementLetterResponse={hasSettlementLetter} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.eft}`}
                element={
                  <Eft openLoanResponse={openLoanDetailsResponse} personalDetailsResponse={personalDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.instantEft}`}
                element={<InstantEft openLoanResponse={openLoanDetailsResponse} />}
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.easyPay}`}
                element={
                  <EasyPay
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetailsResponse={personalDetailsResponse}
                  />
                }
              />
            </Route>
          </Routes>
        </Container>
      );
    }
    if (
      openLoanDetailsResponse.openLoanStatus === 8 &&
      !openLoanDetailsResponse.loanStateResponse?.applicationInfo?.isDeclined
    ) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <CounterOffer
            setOpenLoanDetailsResponse={setOpenLoanDetailsResponse}
            openLoanResponse={openLoanDetailsResponse}
          />
        </Container>
      );
    }
  }

  if (openLoanDetailsResponse?.isDne)
    content = (
      <Container disableGutters sx={{ paddingX: '1.6rem' }}>
        <DoNotEngage />
      </Container>
    );

  return (
    <div className={styles.container}>
      {isLoading ? <Loading text="Please wait while we retrieve your account details" /> : content}
      <Container disableGutters sx={{ paddingX: '1.6rem' }}>
        {showOffersText && (
          <Typography variant="body1" fontWeight={300} marginBottom={'3.4rem'}>
            Below are some possible ways to <b>save</b> on your monthly expenses, why not give it a try?
          </Typography>
        )}
        <OptimonkContainer showContainer={showOptimonkContainer} />
      </Container>
    </div>
  );
};

export default AccountSummary;
