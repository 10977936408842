/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
/* Turn rule off for file as used for setting values on page from response. If response is null then logic should remain. */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import './../../../../../styles/_forms.scss';
import './../../../../../styles/_buttons.scss';
import { AddressDetails, IAddressDetails } from '../../../../../clients/AccountClient';
import { addressSchema } from '../../../../../schemas/Schemas';
import { Form, useForm } from '../../../../../components/Form/Form';
import {
    LABEL_COLUMN_SIZE,
    INPUT_COLUMN_SIZE,
    FULL_COLUMN_SIZE,
} from '../../../../../utils/GridColumnSizeDefinitions';
import useAccountClient from '../../../../../hooks/account/Client';
import Loading from '../../../../../components/Loading/Loading';
import InputFormController from '../../../../../components/MuiInput/FormControllers/InputFormController';
import { provinceMappings } from '../../../../../models/SelectOptions';
import SelectFormController from '../../../../../components/MuiInput/FormControllers/SelectFormController';
import { Box, Stack, Typography } from '@mui/material';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import TextButton from '../../../../../components/Buttons/TextButton';

interface IChangeAddressLNProps {
    closeEditing: (isUpdated: boolean) => Promise<void>;
    address?: AddressDetails;
}

const ChangeAddressLN: React.FunctionComponent<IChangeAddressLNProps> = ({
    closeEditing,
    address,
}: IChangeAddressLNProps) => {
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const client = useAccountClient();
    const { displaySnackBar } = useSnackBar();

    const addressForm = useForm({
        criteriaMode: 'all',
        mode: 'onBlur',
        schema: addressSchema,
    });

    useEffect(() => {
        addressForm.setValue('houseNumber', address?.houseNumber ?? '');
        addressForm.setValue('streetName', address?.street ?? '');
        addressForm.setValue('suburb', address?.suburb ?? '');
        addressForm.setValue('city', address?.town ?? '');
        addressForm.setValue('province', address?.district ?? '');
        addressForm.setValue('postalCode', address?.postCode ?? '');
        // eslint-disable-next-line
    }, []);

    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    const updateAddress = async (data: any) => {
        try {
            setIsSubmitLoading(true);
            const updateAddressRequest: IAddressDetails = {};
            updateAddressRequest.postCode = data.postalCode;
            updateAddressRequest.suburb = data.suburb;
            updateAddressRequest.town = data.city;
            updateAddressRequest.houseNumber = data.houseNumber;
            updateAddressRequest.street = data.streetName;
            updateAddressRequest.district = data.province;
            updateAddressRequest.country = data.country;

            const response: AddressDetails = await client.updateAddress(new AddressDetails(updateAddressRequest));

            if (response !== null) {
                displaySnackBar('Successfully updated address details', 'success');
                await closeEditing(true);
            }
        } catch (error) {
            displaySnackBar('Oops! An error occurred. Please try again.', 'error');
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const content = (
        <Form form={addressForm} onSubmit={(data) => updateAddress(data)}>
            <Grid container marginBottom={'2.2rem'}>
                <Grid item xs={FULL_COLUMN_SIZE}>
                    <Typography
                        variant="h3"
                        variantMapping={{ h3: 'h2' }}
                        fontSize={{ xs: '2.4rem' }}
                        marginBottom={"2.4rem"}
                    >
                        Current address
                    </Typography>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>House Number</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <InputFormController
                        name="houseNumber"
                        label=""
                        control={addressForm.control}
                        placeholder="House number"
                        register={addressForm.register}
                    />
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>Street Name</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <InputFormController
                        name="streetName"
                        label=""
                        control={addressForm.control}
                        placeholder="Street name"
                        register={addressForm.register}
                    />
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>Suburb</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <InputFormController
                        name="suburb"
                        label=""
                        control={addressForm.control}
                        placeholder="Suburb"
                        register={addressForm.register}
                    />
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>City</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <InputFormController
                        name="city"
                        label=""
                        control={addressForm.control}
                        placeholder="City"
                        register={addressForm.register}
                    />
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>Province</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <Box>
                        <SelectFormController
                            name="province"
                            label="Province"
                            menuItems={provinceMappings}
                            register={addressForm.register}
                            control={addressForm.control}
                        />
                    </Box>
                </Grid>

                <Grid item xs={FULL_COLUMN_SIZE} sm={LABEL_COLUMN_SIZE}>
                    <label>Postcode</label>
                </Grid>
                <Grid item xs={FULL_COLUMN_SIZE} sm={INPUT_COLUMN_SIZE}>
                    <InputFormController
                        name="postalCode"
                        label=""
                        control={addressForm.control}
                        placeholder="Postalcode"
                        register={addressForm.register}
                    />
                </Grid>
                {isSubmitLoading ? (
                    <Grid item xs={FULL_COLUMN_SIZE}>
                        <Stack
                            alignItems={{ xs: 'center', sm: 'flex-end' }}
                            marginTop={'1rem'}
                        >
                            <Box textAlign={"center"} width={{ xs: "100%", sm: "25rem" }}>
                                <Loading />
                            </Box>
                        </Stack>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={FULL_COLUMN_SIZE}>
                            <Stack
                                flexGrow={1}
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                alignItems={{ xs: 'flex-start', sm: 'center' }}
                                justifyContent={{ xs: 'center', sm: 'space-between' }}
                                marginTop={'1rem'}
                            >
                                <TextButton removePadding onClick={() => closeEditing(false)}>Cancel</TextButton>
                                <PrimaryButton type="submit">Update</PrimaryButton>
                            </Stack>
                        </Grid>
                    </>
                )}
            </Grid>
        </Form>
    );
    return <>{content}</>;
};

export default ChangeAddressLN;
