import { Avatar, Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import TrustIcon from '../../../../../assets/img/webp/handshake.webp';
import { theme } from '../../../../../theme/Theme';
import CreditLifeOptions from '../../../../../components/CreditLifePlus/CreditLifeOptions';
import CreditLifeBreakdown from '../../../../../components/CreditLifePlus/CreditLifeBreakdown';
import { useEffect, useState } from 'react';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import { useWizardOutletContext } from '../../../Wizard';
import useLoanClient from '../../../../../hooks/loan/Client';
import {
  GetInstallmentLoanQuotationResponse,
  GetShortTermLoanQuotationResponse,
} from '../../../../../clients/LoanClient';
import { initialSliderConfig } from '../../../../../components/Sliders/constants';
import Loading from '../../../../../components/Loading/Loading';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import { useTracking } from '../../../../../Tracking/TrackingContext';
import { CreditLifePlusBeneficiaryDetailsKey } from './CreditLifePlusBeneficiary';
import DeathBenefitDescriptionPopup from '../../../../../components/CreditLifePlus/DeathBenefitDescriptionPopup';
import SecondaryButton from '../../../../../components/Buttons/SecondaryButton';
import HandshakeIcon from '../../../../../assets/img/Handshake.png';

export interface StateProps {
  quotation: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined;
  creditLifeSavings?: number;
}

const CreditLifePlus: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const loanClient = useLoanClient();
  const { next, goToProcessing } = useWizardOutletContext();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const [quotationWithCreditLife, setQuotationWithCreditLife] = useState<
    GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined
  >();
  const [quotationWithDeathBenefit, setQuotationWithDeathBenefit] = useState<
    GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined
  >();
  const { displaySnackBar } = useSnackBar();
  const { TrackError } = useTracking();
  const [showDeathBenefitPopup, setShowDeathBenefitPopup] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getQuotation = async (
    deathBenefitOptedIn: boolean
  ): Promise<GetShortTermLoanQuotationResponse | GetInstallmentLoanQuotationResponse | undefined> => {
    const applyValues = getApplyValues();
    const isInstallmentLoan = initialSliderConfig.installmentLoanConfig.productId === applyValues?.productId;

    try {
      if (isInstallmentLoan) {
        const ILQuotationResponse = await loanClient.getInstallmentLoanQuotation(
          applyValues.productId,
          applyValues.amount,
          applyValues.term,
          applyValues.salaryDay,
          new Date(applyValues.applicationDate),
          !deathBenefitOptedIn,
          false,
          deathBenefitOptedIn
        );

        return ILQuotationResponse;
      } else {
        const STLQuotationResponse = await loanClient.getShortTermLoanQuotation(
          applyValues.productId,
          applyValues.amount,
          applyValues.term,
          applyValues.salaryDay,
          new Date(applyValues.applicationDate),
          !deathBenefitOptedIn,
          false,
          deathBenefitOptedIn
        );

        return STLQuotationResponse;
      }
    } catch (error) {
      throw `${isInstallmentLoan
        ? 'A server error occured while calling getInstallmentLoanQuotation'
        : 'A server error occured while calling getShortTermLoanQuotation'
      }`;
    }
  };

  const getApplyValues = () => {
    const applyValuesString = localStorage.getItem('applyValues');
    if (!applyValuesString) throw 'Apply values missing from local storage';
    return JSON.parse(applyValuesString);
  };

  const onLoad = async () => {
    try {
      setIsFetchingData(true);
      const quotationWithDeathBenefitPromise = getQuotation(true);
      const quotationWithCreditLifePromise = getQuotation(false);
      const [quotationWithDeathBenefitResponse, quotationWithCreditLifeResponse] = await Promise.all([
        quotationWithDeathBenefitPromise,
        quotationWithCreditLifePromise,
      ]);
      setQuotationWithDeathBenefit(quotationWithDeathBenefitResponse);
      setQuotationWithCreditLife(quotationWithCreditLifeResponse);
      setIsFetchingData(false);
    } catch (error) {
      displaySnackBar('Oops! An error occurred. Please try again.', 'error');
      TrackError('Missing blackbox data in journey');
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = (continueWithDeathBenefit: boolean) => {
    const applyValuesString = localStorage.getItem('applyValues');
    const applyValues = JSON.parse(applyValuesString ?? '');
    localStorage.setItem('applyValues', JSON.stringify({ ...applyValues, includeInsurance: !continueWithDeathBenefit }));

    // handle navigation
    if (!continueWithDeathBenefit) {
      localStorage.removeItem(CreditLifePlusBeneficiaryDetailsKey);
      next({ quotation: quotationWithCreditLife });
    } else {
      next({ quotation: quotationWithDeathBenefit, creditLifeSavings: quotationWithCreditLife?.insuranceFee });
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <Container disableGutters sx={{ marginTop: { xs: '0', sm: '6rem' }, padding: '0 1.6rem' }}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Stack justifyContent={{ xs: 'center', sm: 'flex-start' }} alignItems={{ xs: 'center', sm: 'flex-start' }}>
          <img
            src={HandshakeIcon}
            alt="Shaking hands with trust icon"
            style={isMobile ? {
              justifyContent: 'center',
              width: '8.5rem',
              height: '7.1rem',
            } :
              {
                justifyContent: 'center',
                width: '10.6rem',
                height: '8.8rem',
                marginLeft: '3rem',
                marginRight: '3rem',
              }
            }
          />
        </Stack>
        <Stack>
          <Typography
            variant="h1"
            fontSize={{ xs: '2.4rem', md: '3.2rem' }}
            fontWeight={400}
            lineHeight={{ xs: '3rem', md: '3.5rem' }}
            marginBottom={{ xs: '2rem', sm: '1.2rem' }}
          >
            Get Death Benefit cover
          </Typography>
          <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2rem'}>
            Ensure{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              your loved ones{' '}
            </Typography>
            are taken care of with an{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              immediate cash{' '}
            </Typography>
            payout. Plus, we’ll cover your loan at{' '}
            <Typography component={'span'} fontSize={'inherit'} fontWeight={600} lineHeight={'inherit'}>
              no additional cost
            </Typography>
            , so you can have peace of mind knowing everything is handled.{'  '}
            <Typography
              component={'span'}
              fontSize={'1.5rem'}
              fontWeight={400}
              fontStyle={'normal'}
              color={theme.palette.primary.main}
              onClick={() => setShowDeathBenefitPopup(true)}
              sx={{ cursor: 'pointer' }}
            >
              Read more
            </Typography>
            <DeathBenefitDescriptionPopup
              open={showDeathBenefitPopup}
              handleClose={() => setShowDeathBenefitPopup(false)}
            />
          </Typography>
          <Stack spacing={'0.8rem'} marginTop={{ xs: '2.8rem', sm: '3.2rem' }}>
            <Typography fontSize={'1.6rem'} fontWeight={400} lineHeight={'2.2rem'}>Benefits</Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>Cover amount</Typography>
              <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>{`R ${quotationWithDeathBenefit?.deathBenefitTotalCover ?? 0}`}</Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>Term of cover</Typography>
              <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>Loan term + 2½ months</Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography fontSize={'1.5rem'} fontWeight={300} lineHeight={'2.2rem'}>Credit Life cover</Typography>
              <Typography fontSize={'1.5rem'} fontWeight={600} lineHeight={'2.2rem'}>No charge</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent={'space-between'} margin={{ xs: '5rem 0 5rem 0', sm: '5rem 0 5rem 3rem' }} spacing={'2rem'}>
        <SecondaryButton onClick={() => handleContinue(false)}>
          Continue with no Death Benefit
        </SecondaryButton>
        <PrimaryButton type="submit" onClick={() => handleContinue(true)}>
          Continue with Death Benefit
        </PrimaryButton>
      </Stack>
    </Container>
  );
};

export default CreditLifePlus;
