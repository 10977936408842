import { Box } from '@mui/material';
import React from 'react';
import { useAppSettings } from '../../contexts/AppSettingsContext';

interface OptimonkContainerProps {
    showContainer: boolean;
}

/**
 * Display Optimonk embedded container based on campaign id. Please note for Optimonk script to work this container has to be on screen when the route changes.
 * Therefore you can't use this after making an API call unless this is used on a static page with no loading indicators. If you need to make an API call make sure this is in the DOM with showContainer set to false and then set it to true after the API call.
 *
 * @param {boolean} showContainer If set to false display prop will be set to none because the Optimonk embedded container needs to be in the DOM as the page changes or else their script doesn't work.
 * @returns {React.FunctionComponent<OptimonkContainerProps>}
 */
const OptimonkContainer: React.FunctionComponent<OptimonkContainerProps> = (props: OptimonkContainerProps) => {
    const { optimonkConfig } = useAppSettings();
    const { showContainer } = props;

    return (
        <Box maxWidth='100%' maxHeight={{ xs: '24.1rem', sm: '48.4rem' }} overflow={'auto'} textAlign={'center'} marginTop={'3.6rem'} marginBottom={'2rem'} display={showContainer ? 'block' : 'none'}>
            <div className="om-embedded-campaign" data-campaign-id={optimonkConfig.campaignId} />
        </Box>
    );
};

export default OptimonkContainer;