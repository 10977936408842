import { Box, LinearProgress, Stack, useTheme } from '@mui/material';
import React from 'react';
import DocumentLogo from '../../assets/img/svg/Document.svg';
import FailedUploadLogo from '../../assets/img/svg/Error_Circle.svg';
import SuccessfulUploadLogo from '../../assets/img/svg/Tick_Circle.svg';
import TextButton from '../Buttons/TextButton';
import FileUploadButton from '../MuiInput/FileUploadButton/FileUploadButton';
import styles from './DocumentUploadStatus.module.scss';

interface DocumentUploadStatusProps {
  filename: string;
  isUploading: boolean;
  successfulUpload: boolean;
  onReplaceDocument: (file: File) => Promise<void>;
  isDisabled: boolean;
  onRemoveDocument: () => void;
}

const DocumentUploadStatus: React.FunctionComponent<DocumentUploadStatusProps> = (props: DocumentUploadStatusProps) => {
  const { filename, onReplaceDocument, onRemoveDocument, successfulUpload, isUploading, isDisabled } = props;
  const theme = useTheme();
  const uploadPercentage = !isUploading && successfulUpload ? 100 : 0;

  return (
    <Box width={'100%'} paddingRight={'10px'}>
      <Stack direction={'row'} paddingTop={'2rem'}>
        <img className={styles['document-logo']} src={DocumentLogo} alt="documentLogo" />
        <Stack width={'calc(100% - 70px)'}>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={'2rem'}>
            <p className={styles['document-name']}>{filename}</p>
            <p className={styles['upload-percentage']}>{`${uploadPercentage}%`}</p>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={uploadPercentage}
            sx={{
              marginLeft: '0!important',
              backgroundColor: successfulUpload || isUploading ? '#757575' : '#D0021B',
              '& .MuiLinearProgress-bar': {
                marginLeft: 0,
                backgroundColor: '#7AC206',
                transition: isUploading ? 'transform .0s linear' : 'transform .2s linear',
              },
            }}
          />
          <Stack direction={'row'} justifyContent={'space-between'}>
            {successfulUpload || isUploading ? (
              <FileUploadButton
                title={'Replace document'}
                isDisabled={isDisabled}
                buttonType={'text'}
                handleFileUpload={onReplaceDocument}
              />
            ) : (
              <Stack height={'5rem'} justifyContent={'center'}>
                <p className={styles['failed-upload-text']}>Failed to upload</p>
              </Stack>
            )}
            {!successfulUpload && !isUploading && (
              <TextButton onClick={onRemoveDocument} disabled={isDisabled} removePadding>
                Remove
              </TextButton>
            )}
          </Stack>
        </Stack>
        {isUploading ? (
          <Box marginLeft={'3rem'} />
        ) : successfulUpload ? (
          <img className={styles['successful-upload-logo']} src={SuccessfulUploadLogo} alt="uploadSuccessfulLogo" />
        ) : (
          <img className={styles['failed-upload-logo']} src={FailedUploadLogo} alt="uploadFailedLogo" />
        )}
      </Stack>
    </Box>
  );
};

export default DocumentUploadStatus;
