import React from 'react';
import { Typography } from '@mui/material';
import styles from './Eft.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import KeyValuePair from '../../../../../models/KeyValuePair';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import { IGetPersonalDetailsResponse } from '../../../../../clients/AccountClient';

interface EftProps {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetailsResponse: IGetPersonalDetailsResponse;
}

const Eft: React.FunctionComponent<EftProps> = ({ openLoanResponse, personalDetailsResponse }: EftProps) => {
  const navigate = useNavigate();
  const { currentBalance } = openLoanResponse.loanData || {};
  const { idNumber } = personalDetailsResponse.personalDetails?.customer || {};

  const bankDetails: KeyValuePair<string>[] = [
    { key: 'Bank', value: 'Absa' },
    { key: 'Account Name', value: 'Savings' },
    { key: 'Beneficiary reference', value: idNumber ? idNumber : 'Your ID number' },
    { key: 'Branch Code', value: '631009' },
    { key: 'Account number', value: '4077955749' },
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles['eft-container']}>
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
      >
        Pay using EFT
      </Typography>
      <div className={styles['eft-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['eft-hint-icon']} />
        <div className={styles['eft-hint-text']}>
          Please note that repayments made by EFT that are within 5 days of your next debit order collection may not be
          processed in time. Once payment is made, this can take 48 working hours to reflect on your account.
        </div>
      </div>
      {bankDetails.map((item, index) => (
        <div key={index} className={styles['eft-table']}>
          <div>{item.key}</div>
          <div>{item.value}</div>
        </div>
      ))}
      <div className={styles['eft-table-bold']}>
        <div>Current Balance</div>
        <div>R {formatMoney(currentBalance ?? 0)}</div>
      </div>

      <div className={styles['eft-back-button-container']}>
        <PrimaryButton onClick={navigateBack}>Back</PrimaryButton>
      </div>
    </div>
  );
};

export default Eft;
