import { Container, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Form, useForm } from "../../../components/Form/Form";
import { limiterFormSchema } from "../../../schemas/Schemas";
import { useSnackBar } from "../../../contexts/SnackBarContext";
import FormInputLabel from "../../../components/Form/FormInputLabel";
import FormInputWrapper from "../../../components/Form/FormInputWrapper";
import InputFormController from "../../../components/MuiInput/FormControllers/InputFormController";
import Loading from "../../../components/Loading/Loading";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import UmbracoCustomerLead from "../../../models/UmbracoCustomerLead";
import { LimiterContext } from "../../../contexts/LimiterContext";

const Limiter: React.FunctionComponent = () => {
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [successfulSubmition, setSuccessfulSubmition] = useState<boolean>(false);
    const { displaySnackBar } = useSnackBar();
    const { limiterSubtitle, limiterHeading } = useContext(LimiterContext);

    const form = useForm({
        criteriaMode: "all",
        mode: "onBlur",
        schema: limiterFormSchema,
    });

    /* eslint-disable  @typescript-eslint/no-explicit-any*/
    const submitDetails = async (data: any) => {
        try {
            setSubmitLoading(true);
            const customerLead: UmbracoCustomerLead = {
                foreName: data.firstname,
                surname: data.lastname,
                email: data.email,
                mobilePhone: data.cellphoneNumber
            }
            const response = await fetch("/Umbraco/Api/CustomerLeads/PostSubmitLead", {
                method: "POST",
                body: JSON.stringify(customerLead),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
            });
            if (!response.ok) throw "error";
            setSubmitLoading(false);
            setSuccessfulSubmition(true);
        } catch (error) {
            setSuccessfulSubmition(false);
            displaySnackBar("Oops! An error occurred. Please try again.", "error");
            console.error(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <>
            <Container maxWidth="lg" sx={{ padding: { md: "1.6rem 5rem 5rem 5rem", xs: "0.6rem 2rem 2rem 2rem" } }}>
                {successfulSubmition ?
                    <Stack gap={3}>
                        <Typography variant="h2" variantMapping={{ h3: "h1" }} fontSize={{ md: "3.2rem", xs: "2.4rem" }} fontWeight={400}>
                            {limiterHeading}
                        </Typography>
                        <Typography variant="body1" variantMapping={{ h3: "h1" }} fontWeight={300}>
                            Thank you for sending us your details
                        </Typography>
                    </Stack>
                    :
                    <Form
                        form={form}
                        onSubmit={(data) => submitDetails(data)}>
                        <Stack gap={"1.7rem"} marginBottom={"1.2rem"}>
                            <Typography variant="h2" variantMapping={{ h3: "h1" }} fontSize={{ md: "3.2rem", xs: "2.4rem" }} fontWeight={400}>
                                {limiterHeading}
                            </Typography>
                            <Typography variant="body1" variantMapping={{ h3: "h1" }} fontWeight={300}>
                                {limiterSubtitle}
                            </Typography>
                        </Stack>
                        <Grid container>
                            <FormInputLabel>First Name</FormInputLabel>
                            <FormInputWrapper>
                                <InputFormController
                                    name="firstname"
                                    label="First Name"
                                    control={form.control}
                                    placeholder="First name"
                                    register={form.register}
                                />
                            </FormInputWrapper>

                            <FormInputLabel>Surname</FormInputLabel>
                            <FormInputWrapper>
                                <InputFormController
                                    name="lastname"
                                    label="Surname"
                                    placeholder="Surname"
                                    register={form.register}
                                    control={form.control}
                                />
                            </FormInputWrapper>

                            <FormInputLabel>Email Address</FormInputLabel>
                            <FormInputWrapper>
                                <InputFormController
                                    name="email"
                                    label="Email"
                                    placeholder="Email Address"
                                    register={form.register}
                                    control={form.control}
                                />
                            </FormInputWrapper>

                            <FormInputLabel>Cell phone number</FormInputLabel>
                            <FormInputWrapper>
                                <InputFormController
                                    name="cellphoneNumber"
                                    label="Cell phone number"
                                    placeholder="Cell phone number"
                                    register={form.register}
                                    control={form.control}
                                />
                            </FormInputWrapper>

                        </Grid>


                        <Stack
                            alignItems={{ md: "flex-end", xs: "center" }}
                            gap={1}
                            marginBottom={5}
                            marginTop={2}
                        >
                            {submitLoading && <Loading />}
                            {!submitLoading && (
                                <PrimaryButton type="submit">Submit</PrimaryButton>
                            )}
                        </Stack>

                    </Form>
                }
            </Container>
        </>
    );
}

export default Limiter;