import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BankAccountType, BankDetails, IBankDetails, UpdateBankResponse } from '../../../clients/AccountClient';
import { bankAccountTypeMappings, bankCodeMappings, bankNameMappings } from '../../../models/SelectOptions';
import { Form, useForm } from '../../../components/Form/Form';
import FormInputLabel from '../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { bankSchema } from '../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import SelectFormController from '../../../components/MuiInput/FormControllers/SelectFormController';
import InputFormController from '../../../components/MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useWizardOutletContext } from '../Wizard';
import ButtonLoadingIndicator from '../../../components/Loading/ButtonLoadingIndicator';
import InformationImage from '../../../assets/img/webp/information.webp';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const BankDetailsPage: React.FunctionComponent = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { displaySnackBar } = useSnackBar();
  const client = useAccountClient();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const { next } = useWizardOutletContext();

  const bankForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: bankSchema,
  });

  const accountTypeWatch = bankForm.watch('accountType');
  const bankNameWatch = bankForm.watch('bankName');

  const fetchData = async () => {
    try {
      const bankPromise = client.getBankAccount();
      const customerPromise = client.getCustomer();
      const [bankResponse, customerResponse] = await Promise.all([bankPromise, customerPromise]);

      if (bankResponse && customerResponse) {
        bankForm.setValue(
          'holderName',
          customerResponse?.customer?.firstName && customerResponse?.customer?.lastName
            ? `${customerResponse.customer?.firstName} ${customerResponse.customer?.lastName}`
            : ''
        );
        bankForm.setValue('accountNumber', bankResponse?.bank?.accountNumber ? bankResponse.bank?.accountNumber : '');
        bankForm.setValue(
          'accountType',
          bankResponse?.bank?.bankAccountType ? bankResponse.bank?.bankAccountType : BankAccountType.Unspecified
        );
        bankForm.setValue('bankName', bankResponse?.bank?.bankName ? bankResponse.bank?.bankName : '');
        bankForm.setValue('bankCode', bankResponse?.bank?.bankCode ? bankResponse.bank?.bankCode : '');
        bankForm.setValue('openedOn', bankResponse?.bank?.openedOn ? bankResponse.bank?.openedOn.toString() : '');
        setIsFetchingData(false);
      }
    } catch (error) {
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((accountTypeWatch || bankNameWatch) && accountTypeWatch !== BankAccountType.Unspecified)
      bankForm.trigger('accountNumber');
  }, [accountTypeWatch, bankNameWatch, bankForm]);

  /* eslint-disable  @typescript-eslint/no-explicit-any*/
  const updateBankAccount = async (data: any) => {
    setSubmitLoading(true);
    const bankCode = bankCodeMappings.find((bankCode) => bankCode.key === bankForm.getValues('bankName'))?.value;
    const bank: IBankDetails = {
      accountNumber: data.accountNumber,
      bankAccountType: data.accountType,
      bankCode: bankCode,
      bankName: data.bankName,
      holderName: data.holderName,
      /*
      openedOn is a required field on interface in the backend but
      it is not actually being used so we are just hard coding it in here
      or else it will fail.
      */
      openedOn: new Date('01/01/2001'),
    };

    try {
      const response: UpdateBankResponse = await client.updateBank(new BankDetails(bank));

      if (response.bankUpdated) {
        displaySnackBar('Bank Details saved', 'success');
        next();
      }
    } catch (error) {
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <FormWrapper title="Bank details">
      <Box display={'flex'} alignItems={{ xs: 'flex-start', md: 'center' }} marginBottom={'20px'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          width={{ xs: '32px', md: '24px' }}
          marginTop={{ xs: '4px', md: '-1px' }}
        >
          <img style={{ width: '16px' }} src={InformationImage} alt="Information image" />
        </Box>
        <Typography variant="body1">
          Please ensure you select the bank account that your income is paid into.
        </Typography>
      </Box>

      <Form form={bankForm} onSubmit={updateBankAccount}>
        <Grid container>
          <FormInputLabel>Bank Name</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="bankName"
              label="Bank Name"
              menuItems={bankNameMappings}
              register={bankForm.register}
              control={bankForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>Account Number</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="accountNumber"
              label="Account number"
              placeholder="Account number"
              register={bankForm.register}
              control={bankForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>Account Type</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="accountType"
              label="Account Type"
              menuItems={bankAccountTypeMappings}
              register={bankForm.register}
              control={bankForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>Account Holder</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="holderName"
              label="Account Holder"
              placeholder="Account holder"
              register={bankForm.register}
              control={bankForm.control}
              disabled
            />
          </FormInputWrapper>
        </Grid>

        <Box sx={{ padding: '0 0 5rem 0' }}>
          {submitLoading && <ButtonLoadingIndicator />}
          {!submitLoading && (
            <Grid container alignItems="center">
              <Grid
                item
                xs={FULL_COLUMN_SIZE}
                display="flex"
                justifyContent={{
                  xs: 'flex-end',
                }}
              >
                <PrimaryButton type="submit">Next</PrimaryButton>
              </Grid>
            </Grid>
          )}
        </Box>
      </Form>
    </FormWrapper>
  );
};

export default BankDetailsPage;
