import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IAddressDetails, UpdateAddressResponse, AddressDetails } from '../../../clients/AccountClient';
import { Form, useForm } from '../../../components/Form/Form';
import FormInputLabel from '../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { addressSchema } from '../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import InputFormController from '../../../components/MuiInput/FormControllers/InputFormController';
import SelectFormController from '../../../components/MuiInput/FormControllers/SelectFormController';
import { provinceMappings } from '../../../models/SelectOptions';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useWizardOutletContext } from '../Wizard';
import ButtonLoadingIndicator from '../../../components/Loading/ButtonLoadingIndicator';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const AddressDetailsPage: React.FunctionComponent = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { next } = useWizardOutletContext();
  const { displaySnackBar } = useSnackBar();
  const client = useAccountClient();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  const addressForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: addressSchema,
  });

  const fetchData = async () => {
    try {
      const response = await client.getAddress();
      if (response) {
        addressForm.setValue('houseNumber', response.address?.houseNumber ? response.address.houseNumber : '');
        addressForm.setValue('streetName', response.address?.street ? response.address.street : '');
        addressForm.setValue('suburb', response.address?.suburb ? response.address.suburb : '');
        addressForm.setValue('city', response.address?.town ? response.address.town : '');
        addressForm.setValue('province', response.address?.district ? response.address.district : '');
        addressForm.setValue('postalCode', response.address?.postCode ? response.address.postCode : '');
        setIsFetchingData(false);
      }
    } catch (error) {
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  interface UpdateAddressData {
    houseNumber: string;
    streetName: string;
    suburb: string;
    city: string;
    province: string;
    postalCode: string;
    country?: string;
  }

  const updateAddress = async (data: UpdateAddressData) => {
    setSubmitLoading(true);
    try {
      const address: IAddressDetails = {
        houseNumber: data.houseNumber,
        street: data.streetName,
        suburb: data.suburb,
        town: data.city,
        district: data.province,
        postCode: data.postalCode,
      };

      const updateResponse: UpdateAddressResponse = await client.updateAddress(new AddressDetails(address));

      if (updateResponse.addressUpdated) {
        displaySnackBar('Address Saved', 'success');
        next();
      }
    } catch (error) {
      console.error(error);
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <FormWrapper title="Current address">
      <Form form={addressForm} onSubmit={updateAddress}>
        <Grid container>
          <FormInputLabel>House number</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="houseNumber"
              label="House number"
              placeholder="House number"
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>
          <FormInputLabel>Street Name</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="streetName"
              label="Street name"
              placeholder="Street name"
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>
          <FormInputLabel>Suburb</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="suburb"
              label="Suburb"
              placeholder="Suburb"
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>
          <FormInputLabel>City</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="city"
              label="city"
              placeholder="City"
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>Province</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="province"
              label="Province"
              menuItems={provinceMappings}
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>

          <FormInputLabel>Postcode</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="postalCode"
              label="Postal Code"
              placeholder="Postcode"
              register={addressForm.register}
              control={addressForm.control}
            />
          </FormInputWrapper>
        </Grid>

        <Box sx={{ padding: '0 0 5rem 0' }}>
          {submitLoading && <ButtonLoadingIndicator />}
          {!submitLoading && (
            <Grid container alignItems="center">
              <Grid
                item
                xs={FULL_COLUMN_SIZE}
                display="flex"
                justifyContent={{
                  xs: 'flex-end',
                }}
              >
                {!submitLoading ? <PrimaryButton type="submit">Next</PrimaryButton> : <Loading text="" />}
              </Grid>
            </Grid>
          )}
        </Box>
      </Form>
    </FormWrapper>
  );
};

export default AddressDetailsPage;
