/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
/* Turn rule off for file as used for setting values on page from response. If response is null then logic should remain. */
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  AffordabilityDetails,
  IAffordabilityDetails,
  UpdateAffordabilityResponse,
} from '../../../clients/AccountClient';
import { Form, useForm } from '../../../components/Form/Form';
import FormInputLabel from '../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { affordabilitySchema } from '../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import InputFormController from '../../../components/MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import LegalInformation from '../../../components/LegalInformation/LegalInformation';
import { useWizardOutletContext } from '../Wizard';
import ButtonLoadingIndicator from '../../../components/Loading/ButtonLoadingIndicator';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const AffordabilityDetailsPage: React.FC<{ isLN?: boolean; isEditing?: boolean }> = ({
  isLN = false,
  isEditing = false,
}: {
  isLN?: boolean;
  isEditing?: boolean;
}) => {
  const { next } = useWizardOutletContext();
  const { displaySnackBar } = useSnackBar();
  const client = useAccountClient();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  const affordabilityForm = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    schema: affordabilitySchema,
  });

  const rentOnChange = affordabilityForm.watch("rentAndTaxes");
  const goodsOnChange = affordabilityForm.watch("goods");
  const transportOnChange = affordabilityForm.watch("transport");
  const loanRepaymentsOnChange = affordabilityForm.watch("loanRepayments");
  const childMaintenanceOnChange = affordabilityForm.watch("childMaintenance");
  const disposableIncome = affordabilityForm.watch("disposableMonthlyIncome");

  const fetchData = async () => {
    try {
      const employmentPromise = client.getEmployment();
      const affordabilityPromise = client.getAffordability();
      const [employmentResponse, affordabilityResponse] = await Promise.all([employmentPromise, affordabilityPromise]);

      if (employmentResponse && affordabilityResponse) {
        affordabilityForm.setValue('grossIncome', employmentResponse.employment!.grossIncome!.toString());
        affordabilityForm.setValue('netIncome', employmentResponse.employment!.netIncome!.toString());
        affordabilityForm.setValue('disposableMonthlyIncome', employmentResponse.employment!.netIncome!.toString());

        affordabilityForm.setValue(
          'rentAndTaxes',
          affordabilityResponse.affordability?.accomodation !== undefined
            ? affordabilityResponse.affordability.accomodation?.toString()
            : ''
        );
        affordabilityForm.setValue(
          'goods',
          affordabilityResponse.affordability?.household !== undefined
            ? affordabilityResponse.affordability.household!.toString()
            : ''
        );
        affordabilityForm.setValue(
          'transport',
          affordabilityResponse.affordability?.transport !== undefined
            ? affordabilityResponse.affordability.transport.toString()
            : ''
        );
        affordabilityForm.setValue(
          'loanRepayments',
          affordabilityResponse.affordability?.otherLoans !== undefined
            ? affordabilityResponse.affordability.otherLoans.toString()
            : ''
        );
        affordabilityForm.setValue(
          'childMaintenance',
          affordabilityResponse.affordability?.childMaintenance !== undefined
            ? affordabilityResponse.affordability.childMaintenance.toString()
            : ''
        );

        setIsFetchingData(false);
      }
    } catch (error) {
      console.log(error);
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    const disposable =
      +affordabilityForm.getValues('netIncome') -
      +affordabilityForm.getValues('rentAndTaxes') -
      +affordabilityForm.getValues('goods') -
      +affordabilityForm.getValues('transport') -
      +affordabilityForm.getValues('loanRepayments') -
      +affordabilityForm.getValues('childMaintenance');

    affordabilityForm.setValue('disposableMonthlyIncome', disposable.toString());
  }, [
    rentOnChange,
    goodsOnChange,
    transportOnChange,
    loanRepaymentsOnChange,
    childMaintenanceOnChange,
    affordabilityForm,
  ]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    affordabilityForm.trigger("disposableMonthlyIncome");
  }, [disposableIncome, affordabilityForm]);

  /* eslint-disable  @typescript-eslint/no-explicit-any*/
  const updateAffordability = async (data: any) => {
    try {
      setSubmitLoading(true);
      const affordabilityDetails: IAffordabilityDetails = {
        grossMonthlyIncome: data.grossIncome,
        monthlyIncome: data.netIncome,
        accomodation: data.rentAndTaxes,
        transport: data.transport,
        household: data.goods,
        otherLoans: data.loanRepayments,
        childMaintenance: data.childMaintenance,
      };

      const response: UpdateAffordabilityResponse = await client.updateAffordabilitylDetails(
        new AffordabilityDetails(affordabilityDetails)
      );

      if (response.affordabilityUpdated) {
        displaySnackBar('Affordability saved', 'success');
        next();
      }
    } catch (error) {
      console.error(error);
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <>
      <FormWrapper title={isLN ? 'Please confirm your monthly expenses' : 'Monthly finances'} removeMargin={isLN}>
        <Form form={affordabilityForm} onSubmit={updateAffordability}>
          <Grid container>
            {!isLN && (
              <>
                <FormInputLabel>Gross monthly income</FormInputLabel>
                <FormInputWrapper>
                  <InputFormController
                    name="grossIncome"
                    label="Gross Income"
                    placeholder=""
                    register={affordabilityForm.register}
                    control={affordabilityForm.control}
                    disabled
                    currencyField
                  />
                </FormInputWrapper>

                <FormInputLabel>Nett monthly income</FormInputLabel>
                <FormInputWrapper>
                  <InputFormController
                    name="netIncome"
                    label="Net Income"
                    placeholder=""
                    register={affordabilityForm.register}
                    control={affordabilityForm.control}
                    disabled
                    currencyField
                  />
                </FormInputWrapper>
              </>
            )}
            <FormInputLabel>Monthly rent rates & taxes (exclude bond repayment)</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="rentAndTaxes"
                label="Rent and taxes"
                placeholder=""
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
              />
            </FormInputWrapper>

            <FormInputLabel>Monthly groceries & household goods</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="goods"
                label="Goods"
                placeholder=""
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
              />
            </FormInputWrapper>

            <FormInputLabel>Monthly commuting costs (exclude car repayments)</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="transport"
                label="Transport"
                placeholder=""
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
              />
            </FormInputWrapper>

            <FormInputLabel>Monthly loan repayments</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="loanRepayments"
                label="Loan Repayments"
                placeholder=""
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
              />
            </FormInputWrapper>

            <FormInputLabel>Monthly child maintenance</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="childMaintenance"
                label="Child Maintenance"
                placeholder=""
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
              />
            </FormInputWrapper>

            <FormInputLabel>Calculated disposable income</FormInputLabel>
            <FormInputWrapper>
              <InputFormController
                name="disposableMonthlyIncome"
                label="Disposable Monthly Income"
                type="number"
                register={affordabilityForm.register}
                control={affordabilityForm.control}
                currencyField
                disabled
              />
            </FormInputWrapper>
          </Grid>
          {isLN && <LegalInformation />}
          <Box sx={{ padding: '0 0 5rem 0' }}>
            {submitLoading && <ButtonLoadingIndicator />}
            {!submitLoading && (
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={FULL_COLUMN_SIZE}
                  display="flex"
                  justifyContent={{
                    xs: 'flex-end',
                  }}
                >
                  <PrimaryButton disabled={isEditing} type="submit">
                    Next
                  </PrimaryButton>
                </Grid>
              </Grid>
            )}
          </Box>
        </Form>
      </FormWrapper>
    </>
  );
};

export default AffordabilityDetailsPage;
