import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { fileTypeFromBlob } from 'file-type';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import uploadIcon from '../../assets/img/svg/upload-icon.svg';
import {
  CreateUploadUrlsRequest,
  CreateUploadUrlsResponse,
  DocumentInfo,
  ICreateUploadUrlsRequest,
  IDocumentInfo,
} from '../../clients/AccountClient';
import { CompleteUploadRequest } from '../../clients/LoanClient';
import { useSnackBar } from '../../contexts/SnackBarContext';
import useAccountClient from '../../hooks/account/Client';
import useLoanClient from '../../hooks/loan/Client';
import { useTracking } from '../../Tracking/TrackingContext';
import { RouterRoutes } from '../../utils/RouterRoutes';
import PrimaryButton from '../Buttons/PrimaryButton';
import DocumentUploadStatus from '../DocumentUploadStatus/DocumentUploadStatus';
import Loading from '../Loading/Loading';
import FileUploadButton from '../MuiInput/FileUploadButton/FileUploadButton';
import styles from './DocumentUploadNew.module.scss';

const max_file_uploads = 3;

interface Document {
  successfulUpload: boolean;
  id: string;
  file: string;
  isUploading: boolean;
  data: File;
}

const DocumentUploadNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [busyUploading, setBusyUploading] = useState(false);
  const navigate = useNavigate();
  const client = useAccountClient();
  const loanClient = useLoanClient();
  const [documents, setDocuments] = useState<Document[]>([]);
  const { displaySnackBar } = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  //loanId gets passed through the state on navigate
  const loanId = location && location.state ? location.state['loanId'] ?? '' : '';
  const { TrackError } = useTracking();

  const updateDocumentStatus = (document: Document, isUploading: boolean, successfulUpload: boolean) => {
    setDocuments((docs) => {
      const newDocs: Document[] = [];
      docs.forEach((doc) => {
        const isDocumentToUpdate = doc.file === document.file;
        if (isDocumentToUpdate) {
          newDocs.push({ ...document, isUploading, successfulUpload });
        } else {
          newDocs.push(doc);
        }
      });
      const docExists = docs.some((doc) => doc.file === document.file);
      if (!docExists) newDocs.push(document);
      return newDocs;
    });
  };

  const UploadDocument = async (document: File) => {
    if (!document) return;

    const documentRequest: ICreateUploadUrlsRequest = { documents: [] };

    let fileName = document.name;

    // If the file input does not pick up a file type then we use the lib to read the filetype from the contents of the file buffer
    // Check https://www.npmjs.com/package/file-type for more info
    if (!document.type) {
      const fileTypeResult = await fileTypeFromBlob(document);
      fileName = `${fileName}.${fileTypeResult?.ext}`
    }

    const documentInfo: IDocumentInfo = {
      documentId: uuidv4().toString(),
      documentDate: new Date(),
      documentName: 'documents',
      fileName: fileName,
    };

    documentRequest.documents?.push(new DocumentInfo(documentInfo));

    const newDocument: Document = {
      file: documentInfo.fileName ?? '',
      id: documentInfo.documentId ?? '',
      successfulUpload: false,
      isUploading: true,
      data: document,
    };

    updateDocumentStatus(newDocument, true, false);

    setBusyUploading(true);

    const createUploadUrlsResponse = await getDocumentUploadUrl(documentRequest, newDocument);

    if (!createUploadUrlsResponse) return;

    await uploadDocumentToS3(createUploadUrlsResponse, [document], newDocument);
  };

  const handleCompleteUpload = async () => {
    try {
      setIsLoading(true);
      const documentIds = documents.map((item) => item.id);
      await completeUpload(documentIds, loanId);
      navigateToMyLoan();
    } catch (error) {
      displaySnackBar("We're currently experiencing a temporary technical issue. Please try again later.", 'error');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDocumentUploadUrl = async (
    documentRequest: ICreateUploadUrlsRequest,
    newDocument: Document
  ): Promise<CreateUploadUrlsResponse | null> => {
    try {
      return await client.createUpload(new CreateUploadUrlsRequest(documentRequest));
    } catch (error) {
      updateDocumentStatus(newDocument, false, false);
      displaySnackBar("We're currently experiencing a temporary technical issue. Please try again later.", 'error');
      TrackError(error, 'Failed to fetch pre-signed document upload URL.');
      setBusyUploading(false);
      return null;
    }
  };

  const uploadDocumentToS3 = async (
    response: CreateUploadUrlsResponse,
    document: File[],
    newDocument: Document
  ): Promise<void> => {
    if (response.documentUrls == null || response.documentUrls == undefined || response.documentUrls.length === 0)
      return;

    const length = response.documentUrls.length;

    for (let i = 0; i < length; i++) {
      let presignedUrl = response.documentUrls[i]?.url;
      if (presignedUrl != undefined) {
        presignedUrl = presignedUrl.includes('localstack')
          ? presignedUrl.replace('localstack', 'localhost')
          : presignedUrl;

        const headers = new Headers();
        headers.append('Content-Type', response.documentUrls[i].contentType as string);

        try {
          const res = await fetch(presignedUrl, {
            method: 'PUT',
            body: document[i],
            headers: headers,
          });

          if (res.ok) {
            updateDocumentStatus(newDocument, false, true);
          }
        } catch (error) {
          updateDocumentStatus(newDocument, false, false);
          displaySnackBar('There was a error with this document, please upload a different document.', 'error');
          TrackError(error, 'Document upload to S3 failed.');
        } finally {
          setBusyUploading(false);
        }
      }
    }
  };

  const completeUpload = async (documentIds: string[], loanId?: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      loanClient
        .completeUpload(
          new CompleteUploadRequest({
            documentIds: documentIds,
            doneSaving: true,
            loanId: loanId,
          })
        )
        .then(() => {
          return resolve();
        })
        .catch((error) => {
          TrackError(error, 'CompleteUpload error occured');
          return reject();
        });
    });
  };

  const handleFileUpload = async (file: File) => {
    const docAlreadyExist = documents.some((doc) => doc.file === file.name);
    if (docAlreadyExist) displaySnackBar('Document already exists', 'success');
    else await UploadDocument(file);
  };

  const replaceDocumentInList = (file: File, documentIndex: number) => {
    setDocuments((docs) => {
      const newDocs: Document[] = [];
      docs.forEach((doc, index) => {
        const isDocumentToReplace = index === documentIndex;
        if (isDocumentToReplace) {
          newDocs.push({ ...doc, file: file.name, isUploading: true, successfulUpload: false, data: file });
        } else {
          newDocs.push(doc);
        }
      });
      return newDocs;
    });
  };

  const removeDocumentByIndex = (documentIndex: number) => {
    setDocuments((docs) => {
      const newDocs: Document[] = [];
      docs.forEach((doc, index) => {
        const isDocumentToRemove = index === documentIndex;
        if (isDocumentToRemove) {
          return;
        } else {
          newDocs.push(doc);
        }
      });
      return newDocs;
    });
  };

  const handleFileReplace = async (file: File, index: number) => {
    const docAlreadyExist = documents.some((doc) => doc.file === file.name);
    if (docAlreadyExist) displaySnackBar('Document already exists', 'success');
    else {
      replaceDocumentInList(file, index);
      await UploadDocument(file);
    }
  };

  const navigateToMyLoan = () => {
    navigate(`/${RouterRoutes.myLoan}`);
  };

  const generateUploadButtonTitle = () => {
    if (documents.length === max_file_uploads) return `Max ${max_file_uploads} documents`;
    if (documents.length > 0) return 'Upload more';
    return 'Upload files';
  };

  useEffect(() => {
    if (!loanId) navigateToMyLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className={styles['container']}>
        <Box margin={'5px 0 25px 0'} display={'flex'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'flex-start'} alignItems={'center'}>
          <Box display={'flex'} justifyContent={'center'}
            width={isMobile ? '100%' : '100px'}
            margin={isMobile ? '5px 0px 30px 0px' : '0px 30px'}
          >
            <img src={uploadIcon} aria-label="Upload Icon" width={isMobile ? '81px' : '125px'} />
          </Box>
          <Box>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              sx={{
                fontWeight: 400,
                paddingBottom: '10px',
              }}
            >
              Upload your bank statement
            </Typography>
            <Typography fontWeight={300}>
              Maximum <strong>(3)</strong> documents <strong>no older than 30 days </strong>
              from the date of application.
            </Typography>
            <Typography paddingTop={isMobile ? '2rem' : '0.5rem'} fontWeight={300}>
              Accepted file types: PDF, PNG, JPEG.
              Please ensure they are not password protected.
            </Typography>
          </Box>
        </Box>
        <Box marginTop={'1rem'} marginBottom={'2rem'} className={styles['button-container']}>
          <Box marginLeft={isMobile ? 0 : "17rem"} marginBottom={'2rem'}>
            {documents.map((item, index) => (
              <>
                <DocumentUploadStatus
                  key={index}
                  filename={item.file}
                  isUploading={item.isUploading}
                  successfulUpload={item.successfulUpload}
                  onReplaceDocument={(file: File) => handleFileReplace(file, index)}
                  isDisabled={isLoading}
                  onRemoveDocument={() => removeDocumentByIndex(index)}
                />
                {index !== documents.length - 1 && < div style={{ borderTop: '1px solid #E4E4E4', margin: '10px' }} />}
              </>
            ))}
          </Box>

          <FileUploadButton
            title={generateUploadButtonTitle()}
            isDisabled={documents.length === max_file_uploads || isLoading}
            buttonType={documents.length > 0 ? 'secondary' : 'primary'}
            handleFileUpload={handleFileUpload}
            width={isMobile ? '100%' : '25rem'}
          />

        </Box>
        <Box display={'flex'} justifyContent={'end'} >
          <Box className={styles['buttons-wrapper']}>
            {isLoading ? (
              <Box textAlign={'center'} width={isMobile ? '100%' : '25rem'}>
                <Loading />
              </Box>
            ) : (
              <PrimaryButton
                disabled={
                  documents.length === 0 || busyUploading || documents.filter((x) => !x.successfulUpload).length > 0
                }
                onClick={handleCompleteUpload}
              >
                Finish
              </PrimaryButton>
            )}
            <Button
              variant="text"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                color: '#008FD5',
                marginTop: '2.4rem',
                fontWeight: '300',
                justifyContent: isMobile ? 'center' : 'left',
                marginBottom: isMobile ? '40px' : '70px'
              }}
              onClick={navigateToMyLoan}
            >
              Upload documents later
            </Button>
          </Box>
        </Box>

      </Container >
    </>
  );
};

export default DocumentUploadNew;
