import React, { useEffect, useState } from 'react';
import { Container, Stack, useMediaQuery } from '@mui/material';
import styles from './ConfirmDetailsSummary.module.scss';
import informationIcon from '../../../../../assets/img/webp/information-2x.webp';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../../components/Buttons/SecondaryButton';
import { theme } from '../../../../../theme/Theme';
import useAccountClient from '../../../../../hooks/account/Client';
import {
  EmploymentDetails,
  GetAffordabilityResponse,
  GetBankResponse,
  GetEmploymentResponse,
  IEmploymentDetails,
} from '../../../../../clients/AccountClient';
import { useNavigate } from 'react-router-dom';
import { RouterRoutes } from '../../../../../utils/RouterRoutes';
import Loading from '../../../../../components/Loading/Loading';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import { MaskBankAccountNumber } from '../../../../../components/Validation/MaskBankAccountNumber';
import ChangeLoanValuesNewDesign from '../../../../../components/ChangeLoanValues/ChangeLoanValuesNewDesign';
import { useWizardOutletContext } from '../../../Wizard';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const ConfirmDetailsSummary: React.FunctionComponent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const accountClient = useAccountClient();
  const [bankDetailsResponse, setBankDetailsResponse] = useState<GetBankResponse>(
    // Initialize with an empty object instead of null
    {} as GetBankResponse
  );
  const [affordabilityDetailsResponse, setAffordabilityDetailsResponse] = useState<GetAffordabilityResponse>(
    // Initialize with an empty object instead of null
    {} as GetAffordabilityResponse
  );
  const [employmentResponse, setEmploymentResponse] = useState<GetEmploymentResponse>(
    // Initialize with an empty object instead of null
    {} as GetEmploymentResponse
  );
  const navigate = useNavigate();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const { displaySnackBar } = useSnackBar();
  const [isEditing, setIsEditing] = useState(false);
  const { next, goToProcessing, goToCreditLifePlus } = useWizardOutletContext();
  const appSettings = useAppSettings();

  const calculateTotalExpenses = (): number => {
    let totalExpenses = 0;
    totalExpenses += affordabilityDetailsResponse.affordability?.accomodation ?? 0;
    totalExpenses += affordabilityDetailsResponse.affordability?.household ?? 0;
    totalExpenses += affordabilityDetailsResponse.affordability?.transport ?? 0;
    totalExpenses += affordabilityDetailsResponse.affordability?.otherLoans ?? 0;
    totalExpenses += affordabilityDetailsResponse.affordability?.childMaintenance ?? 0;
    return totalExpenses;
  };

  const calculateDisposableIncome = (): number => {
    const totalExpenses = calculateTotalExpenses();
    const disposableIncome = (employmentResponse.employment?.netIncome ?? 0) - totalExpenses;
    return disposableIncome;
  };

  const onUpdateDetailsClicked = () => {
    next();
  };

  const onNextClicked = () => {
    appSettings.insurance.deathBenefitEnabled ? goToCreditLifePlus() : goToProcessing();
  };

  const updateSalaryDay = async (employmentResponse: GetEmploymentResponse) => {
    try {
      const applyValuesString = localStorage.getItem('applyValues');
      const applyValues = JSON.parse(applyValuesString ?? '');

      /*
            Here we are checking if the salary day is 0 if it is then set the local storage value the same as what we have stored in the database.
            If the salary day is not 0 and is different from the one in the employment page then update the salary day in the database.
            */
      if (applyValues && applyValues.salaryDay === 0) {
        localStorage.setItem(
          'applyValues',
          JSON.stringify({ ...applyValues, salaryDay: employmentResponse.employment?.salaryDay })
        );
      } else if (
        applyValues &&
        applyValues.salaryDay !== 0 &&
        applyValues.salaryDay !== employmentResponse.employment?.salaryDay
      ) {
        const employmentDetails: IEmploymentDetails = {
          ...employmentResponse.employment,
          salaryDay: applyValues.salaryDay,
        };
        await accountClient.updateEmployment(new EmploymentDetails(employmentDetails));
      }
    } catch (error) {
      displaySnackBar('Oops! An error occurred. Please try again.', 'error');
    }
  };

  const fetchData = async () => {
    try {
      setIsFetchingData(true);
      const bankDetailsPromise = accountClient.getBankAccount();
      const affordabilityDetailsPromise = accountClient.getAffordability();
      const employmentPromise = accountClient.getEmployment();
      const [bankDetailsResponse, affordabilityDetailsResponse, employmentResponse] = await Promise.all([
        bankDetailsPromise,
        affordabilityDetailsPromise,
        employmentPromise,
      ]);
      setBankDetailsResponse(bankDetailsResponse);
      setAffordabilityDetailsResponse(affordabilityDetailsResponse);
      setEmploymentResponse(employmentResponse);
      await updateSalaryDay(employmentResponse);
      setIsFetchingData(false);
    } catch (error) {
      displaySnackBar('Oops! An error occurred. Please try again.', 'error');
      navigate(`/${RouterRoutes.myLoan}`);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleLoanValueEdited = async (editing: boolean) => {
    setIsEditing(editing);
    await updateSalaryDay(employmentResponse);
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  return (
    <Container maxWidth="lg" sx={{ padding: { md: '1.6rem 5rem 5rem 5rem', xs: '0.6rem 2rem 5rem 2rem' } }}>
      <ChangeLoanValuesNewDesign onEditing={handleLoanValueEdited} />
      <div
        className={
          styles[isMobile ? 'details-confirmation-hint-container-mobile' : 'details-confirmation-hint-container']
        }
      >
        <img src={informationIcon} aria-label="Information Icon" className={styles['details-confirmation-hint-icon']} />
        <div className={styles['details-confirmation-hint-text']}>
          Before you proceed, please check if any of your details have changed. Here are{' '}
          <b style={{ fontWeight: 600 }}>some</b> important details below.
        </div>
      </div>

      <div className={styles['details-confirmation-table']}>
        <div>Bank name</div>
        <div className={styles['text-bold']}>{bankDetailsResponse.bank?.bankName}</div>
      </div>

      <div className={styles['details-confirmation-table']}>
        <div>Account number</div>
        <div className={styles['text-bold']}>{MaskBankAccountNumber(bankDetailsResponse.bank?.accountNumber)}</div>
      </div>

      <div className={styles['details-confirmation-table']}>
        <div>Net monthly income (after tax)</div>
        <div className={styles['text-bold']}>{`R ${employmentResponse.employment?.netIncome}`}</div>
      </div>

      <div className={styles['details-confirmation-table']}>
        <div>Total monthly expenses</div>
        <div className={styles['text-bold']}>{`R ${calculateTotalExpenses()}`}</div>
      </div>

      <div className={styles['details-confirmation-table']}>
        <div>Disposable income</div>
        <div className={styles['text-bold']}>{`R ${calculateDisposableIncome()}`}</div>
      </div>

      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        spacing={'2rem'}
        padding="3.4rem 0rem 0rem 0rem"
      >
        <SecondaryButton onClick={onUpdateDetailsClicked} disabled={isEditing}>
          Update my details
        </SecondaryButton>
        <PrimaryButton onClick={onNextClicked} disabled={isEditing}>
          Next
        </PrimaryButton>
      </Stack>
    </Container>
  );
};

export default ConfirmDetailsSummary;
