import React, { useContext } from 'react';
import { AppSettings } from '../models/AppSettings';

export const defaultAppSettings: AppSettings = {
  apiEndpoints: {
    account: window._env_.ACCOUNT_API_URL,
    loan: window._env_.LOAN_API_URL,
    leads: window._env_.LEADS_API_URL,
  },
  datadogConfig: {
    datadogEnabled: window._env_.DATADOG_ENABLED,
    applicationId: window._env_.APPLICATION_ID,
    clientToken: window._env_.CLIENT_TOKEN,
    site: window._env_.SITE,
    defaultPrivacyLevel: window._env_.DEFAULT_PRIVACY_LEVEL,
    env: window._env_.ENV,
    service: window._env_.SERVICE,
    sessionReplaySampleRate: window._env_.SESSSION_REPLAY_SAMPLE_RATE,
    sessionSampleRate: window._env_.SESSION_Sample_Rate,
    trackResources: window._env_.TRACK_RESOURCES,
    trackLongTasks: window._env_.TRACK_LONG_TASKS,
    trackUserInteractions: window._env_.TRACK_USER_INTERACTIONS,
    version: window._env_.DD_VERSION,
  },
  repayOptions: {
    directDebitEnabled: window._env_.DIRECT_DEBIT_ENABLED,
    instantEFTEnabled: window._env_.INSTANT_EFT_ENABLED,
    easyPayEnabled: window._env_.EASY_PAY_ENABLED,
    eftEnabled: window._env_.EFT_ENABLED,
  },
  blackBoxName: window._env_.BLACK_BOX_NAME,
  companyDetails: {
    companyName: window._env_.COMPANY_NAME,
    registrationNumber: window._env_.COMPANY_REGISTRATION_NUMBER,
    ncrRegistrationNumber: window._env_.COMPANY_NCA_REGISTRATION_NUMBER,
    companyAddress: window._env_.COMPANY_ADDRESS,
    CompanyContactNumber: window._env_.COMPANY_CONTACT_NUMBER,
    companyContactEmail: window._env_.COMPANY_CONTACT_EMAIL,
  },
  isPpc: window._env_.IS_PPC,
  ppcOverides: window.PPC_OVERIDES,
  registerDisabled: window._env_.REGISTER_DISABLED,
  cfTurnstileConfig: {
    cfTurnstileDisabled: window._env_.CF_TURNSTILE_DISABLED,
    cfTurnstileForgotPasswordWidgetSiteKey: window._env_.CF_TURNSTILE_FORGOT_PASSWORD_WIDGET_SITE_KEY,
    cfTurnstileLoginWidgetSiteKey: window._env_.CF_TURNSTILE_LOGIN_WIDGET_SITE_KEY,
    cfTurnstileOtpWidgetSiteKey: window._env_.CF_TURNSTILE_OTP_WIDGET_SITE_KEY,
    cfTurnstileRegisterWidgetSiteKey: window._env_.CF_TURNSTILE_REGISTER_WIDGET_SITE_KEY,
  },
  captchaProvider: window._env_.CAPTCHA_PROVIDER,
  limiter: window._env_.LIMITER,
  limiterHeading: window._env_.LIMITER_HEADING,
  limiterSubtitle: window._env_.LIMITER_SUBTITLE,
  urgentMessageConfig: {
    urgentMessageEnabled: window._env_.URGENT_MESSAGE_ENABLED,
    urgentMessageHeading: window._env_.URGENT_MESSAGE_HEADING,
    urgentMessageText: window._env_.URGENT_MESSAGE_TEXT,
  },
  googleTagManager: {
    gtmId: window._env_.GTM_ID,
  },
  insurance: {
    carInsuranceEnabled: window._env_.CAR_INSURANCE_ENABLED,
    funeralCoverEnabled: window._env_.FUNERAL_COVER_ENABLED,
    funeralCoverUrl: window._env_.FUNERAL_COVER_URL,
    carInsuranceUrl: window._env_.CAR_INSURANCE_URL,
    insuranceContactNumber: window._env_.INSURANCE_CONTACT_NUMBER,
    insuranceContactEmail: window._env_.INSURANCE_CONTACT_EMAIL,
    deathBenefitEnabled: window._env_.DEATH_BENEFIT_ENABLED,
    deathBenefitIncludeBeneficiaryDetails: window._env_.DEATH_BENEFIT_INCLUDE_BENEFICIARY_DETAILS,
  },
  documents: {
    legalDocumentsValidityPeriod: window._env_.LEGAL_DOCUMENTS_VALIDITY_PERIOD,
  },
  optimonkConfig: {
    campaignId: window._env_.OPTIMONK_CAMPAIGN_ID,
  },
  pageToggles: {
    newDocumentUploadPage: window._env_.NEW_DOCUMENT_UPLOAD_PAGE,
  },
  incomeVerification: {
    openBankingAutoApprovalEnabled: window._env_.OPEN_BANKING_AUTO_APPROVAL_ENABLED
  },
};

export const AppSettingsContext = React.createContext<AppSettings>(defaultAppSettings);

export const useAppSettings = () => {
  return useContext(AppSettingsContext);
};

interface AppSettingsContextProps {
  children: React.ReactNode;
}

export const AppSettingsContextProvider = (props: AppSettingsContextProps) => {
  return <AppSettingsContext.Provider value={defaultAppSettings}>{props.children}</AppSettingsContext.Provider>;
};
