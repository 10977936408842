import { LoanClient } from '../../../../../clients/LoanClient';

export const RedirectToTruId = async (loanClient: LoanClient, loanId: string | undefined, setOpenErrorDialogPopup: (open: boolean) => void) => {
  try {
    if (loanId === undefined || loanId.trim().length === 0) {
      throw new Error('loanId is undefined');
    }

    const truIdURL = await loanClient.getTruIdRedirectUrl(loanId)

    if (truIdURL) {
      window.location.replace(truIdURL);
    } else {
      setOpenErrorDialogPopup(true);
    }
  }catch (e){
    console.error(e);
    setOpenErrorDialogPopup(true);
  }
}
